.navbar-brand img {
  width: 85%;
}

@media screen and (max-width: 480px) {
  .navbar-brand {
    width: 70%;
  }

  .navbar-brand img {
    width: 70%;
  }
}

.navbar-nav .nav-item .nav-Link {
  margin: 0 15px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: normal;
  position: relative;
  text-transform: uppercase;
}

.nav-Link.active {
  color: #3cd9d2 !important;
  font-weight: 600;
}

.nav-Link.active:before {
  position: absolute;
  content: "";
  width: 35%;
  /* height: 1px; */
  border-bottom: 2px solid #3cd9d2;
  bottom: -4px;
}

.nav-Link:before {
  position: absolute;
  content: "";
  width: 0%;
  /* height: 1px; */
  border-bottom: 2px solid rgba(23, 66, 127, 0.9);
  bottom: -4px;
  transition: width 0.3s;
}

.nav-Link:hover:before {
  width: 35%;
}

.navbar-nav {
  position: relative;
  z-index: 3333;
}

.dropdown-menu {
  border-radius: 0px !important;
  border: 0px !important;
  padding: 30px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.dropdown-menu li {
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.dropdown-menu li a {
  padding: 7px;
}

.dropdown-menu li a:hover {
  /* background: rgba(255, 255, 255, 0.3) !important; */
}

@media (max-width: 991px) {
  .nav-item {
    border-bottom: 1px solid rgba(22, 93, 61, 0.3);
    padding: 12px 25px;
    text-align: center;
  }

  .nav-item a {
    text-align: center;
  }

  .nav-item a:hover {
    color: #17427f !important;
  }

  .nav-Link.active {
    color: #fff !important;
  }

  .nav-item.dropdown-menu ul {
    background-color: #17427f !important;
  }
}
