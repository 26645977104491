.skeleton {
  background-color: rgb(199, 199, 199);
  margin: 0.75rem 0;
  border-radius: 0.25rem;
}

.skeleton.image {
  height: 300px;
}

.skeleton.title {
  height: 2.25rem;
  margin-bottom: 1rem;
}
.skeleton.title2 {
  height: 1.25rem;
  margin-bottom: 1rem;
}

.skeleton.text {
  height: 0.75rem;
}

.skeleton.width-100 {
  width: 100%;
}

.skeleton.width-50 {
  width: 50%;
}

.skeleton.profile-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

/* animation */
/* @keyframes pulse {
    50% {
        opacity: .5;
    }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
} */

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* .skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  .skeleton-text {
    width: 100%;
    height: .5rem;
    margin-bottom: .25rem;
    border-radius: .125rem;
  }
  
  .skeleton-text:last-child {
    margin-bottom: 0;
    width: 80%;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
   */
